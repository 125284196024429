import { createSlice } from '@reduxjs/toolkit';
import { getItem } from '#/shared/utils/storage';
import {
  MANUAL_LOCATION_STORAGE_KEY,
  GEOLOCATION_APPROVED_STORAGE_KEY,
  SERVICE_STATE,
} from '#/shared/constants';
import { positionToLocation } from '#/shared/utils/location';
import geocoderConfig from '#/geocoder-config';
import locationConfig from '#/location-config';

const initialState = {
  geocoderConfig,
  locationConfig,
  currentPosition: {
    coords: null,
    error: null,
    fetching: false,
    outOfBounds: false,
  },
  currentLocation: getItem(MANUAL_LOCATION_STORAGE_KEY, null),
  selectedLocation: null,
  geolocationApproved: getItem(GEOLOCATION_APPROVED_STORAGE_KEY, null),
  geolocationPause: false,
  isManualLocation: !!getItem(MANUAL_LOCATION_STORAGE_KEY, null),
  watchId: null,
  bounds: null,
  vehicleRoute: null,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    positionFetching(state, action) {
      state.currentPosition = {
        error: null,
        fetching: true,
        coords: null,
        outOfBounds: action.payload.attemptingGeoRefresh || false,
      };
    },
    positionError(state, action) {
      return {
        ...state,
        currentPosition: {
          error: action.payload,
          fetching: false,
          coords: action.payload.coords,
          outOfBounds: false,
        },
        currentLocation: null,
        geolocationApproved: false,
      };
    },
    geolocationOutOfBounds(state) {
      return {
        ...state,
        geolocationApproved: false,
        currentPosition: {
          error: null,
          fetching: false,
          coords: null,
          outOfBounds: true,
        },
        watchId: null,

        // this is needed to clear the current location if the user steps out
        // of bounds while using geolocation services for their current location
        currentLocation: !state.isManualLocation ? null : state.currentLocation,
      };
    },
    positionReverseGeocoded(state, action) {
      return {
        ...state,
        currentPosition: {
          error: null,
          fetching: false,
          coords: action.payload.coords,
          outOfBounds: false,
        },
        currentLocation: positionToLocation(
          action.payload,
          action.payload.name
        ),
        isManualLocation: false,
        geolocationApproved: true,
      };
    },
    manualLocationReverseGeocoded(state, action) {
      return {
        ...state,
        currentLocation: {
          lat: action.payload.lat,
          lon: action.payload.lon,
          name: action.payload.name,
          state: action.payload.state || SERVICE_STATE,
        },
        isManualLocation: true,
      };
    },
    manualLocationRemoved(state) {
      return {
        ...state,
        currentLocation: null,
        isManualLocation: false,
      };
    },
    watchStarted(state, action) {
      return {
        ...state,
        watchId: action.payload.watchId,
        isManualLocation: false,
        geolocationApproved: true,
      };
    },
    watchCleared(state) {
      state.watchId = null;
    },
    // TODO: refactor CurrentPositionManager to eliminate the need
    //  have another flag set in the Redux store
    watchPaused(state) {
      state.geolocationPause = true;
    },
    watchResumed(state) {
      state.geolocationPause = false;
    },
    setSelectedLocationSuccess(state, action) {
      state.selectedLocation = action.payload;
    },
    setBounds(state, action) {
      state.bounds = action.payload;
    },
    setVehicleRoute(state, action) {
      state.vehicleRoute = action.payload;
    },
  },
});

export const {
  positionFetching,
  positionError,
  geolocationOutOfBounds,
  positionReverseGeocoded,
  manualLocationReverseGeocoded,
  manualLocationRemoved,
  watchStarted,
  watchCleared,
  watchPaused,
  watchResumed,
  setSelectedLocationSuccess,
  setBounds,
  setVehicleRoute,
} = locationSlice.actions;
export default locationSlice.reducer;

// define selectors for objects in the slice so their not anonymous and
// can be memoized if needed:
// https://redux.js.org/usage/deriving-data-selectors#using-selectors-effectively
export const selectCurrentLocation = s => s.shared.location.currentLocation;
export const selectCurrentPosition = s => s.shared.location.currentPosition;
export const selectSelectedLocation = s => s.shared.location.selectedLocation;
export const selectBounds = s => s.shared.location.bounds;
