import wrapRootElement from './.gatsby/wrap-root-element';

// TODO: Once the below ticket is resolved `core-js` imports here can be
//  removed and the package should be removed from the package.json since it
//  should come in as a transitive dependency via Gatsby, while it remains
//  an explicit its version need to be synced with the version Gatsby
//  requires: https://trimet.atlassian.net/browse/PUBWEB-1751
import 'core-js/actual/array/at';

// disable gatsby's scroll restoration so that it doesn't disrupt
// the programmatic scrolling that done to reset the map size on mobile
const shouldUpdateScroll = () => false;

export { wrapRootElement, shouldUpdateScroll };
